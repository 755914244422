.slogan {
  text-align: center;
  margin-top: 3rem;
}

.slogan h1 {
  background: linear-gradient(to right, #FF9816, #F476AB, #8F53A8);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Fredericka the Great', cursive;
  font-size: 2.5rem;
}

.welcome {
  margin-top: 2rem;
  text-align: center;
}

.welcome span {
  display: block;
  background-color: #FFEDA3;
}

.home-container p {
  text-align: center;
  line-height: 1.5;
}

.projects-covers {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.projects-covers div {
  width: 100%;
  cursor: pointer;
}

.projects-covers div img {
  border-radius: 8px;
  width: 100%;

}

@media screen and (min-width: 768px) {

  .slogan {
    text-align: left;
  }

  .welcome {
    text-align: left;
  }

  .welcome span {
    display: inline-block;
    padding: .5rem;
  }

  .home-container p {
    text-align: left;
  }

}

@media screen and (hover:none) {
  .project-hover {
    text-align: center;
    margin-top: 1rem;
  }
}


@media screen and (hover:hover) {
  .project-main {
    position: relative;
  }

  .project-hover {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: none;
  }
  
  .project-hover h2 {
    text-decoration: underline;
    margin-bottom: .5rem;
  }

  .project-hover p {
    font-size: .75rem;
  }

  .project-main:hover .project-hover {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .projects-coming {
    position: relative;
  }

  .projects-coming::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; 
  }

  .projects-coming:hover::after {
    background: rgba(0, 0, 0, .7);
    content: 'Coming Soon';
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 1.25rem;
  }
}