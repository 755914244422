.nav-mobile-sidebar {
  position: absolute;
  height: calc(100vh - var(--navbarHeight));
  width: 100%;
  top: var(--navbarHeight);
  right: 0;
  z-index: 2;
  transition: all ease-in-out 0.3s;
  
}

.nav-mobile-disable-sidebar {
  transform: translateX(100%);
  transition: all ease-in-out 0.3s;

}

.nav-mobile-sidebar-links {
  display: flex;
  height: 100%;
  position: relative;
}

.nav-mobile-sidebar-links img {
  display: block;
  width: 2rem;
  position: absolute;
  right: 10%;
  top: 5%;
}

.nav-mobile-sidebar-links ul {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
}


@media screen and (min-width: 1024px) {
  .nav-mobile-sidebar {
    display: none;
  }
}

