.navbar-container {
  height: var(--navbarHeight);
  font-weight: 600;
}

.navbar-desktop {
  display: none;
}

.navbar-mobile  {
  max-width: 96%;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.navbar-mobile-menu {
  height: var(--navbarHeight);
  display: flex;
  align-items: center;
}

.navbar-mobile-menu img {
  width: 2.5rem;
}

.navbar-mobile-logo {
  height: var(--navbarHeight);
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-mobile-logo a {
  height: 100%;
}

.navbar-mobile-logo img {
  max-height: 100%;
}

@media screen and (min-width: 1024px) {
  .navbar-container {
    height: 20vh;
  }

  .navbar-desktop {
    display: flex;
    justify-content: space-between;
    height: 20vh;
  }

  .navbar-mobile {
    display: none;
  }

  .navbar-desktop-logo {
    height: 100%;
  }

  .navbar-desktop-logo a {
    height: 100%;
  }

  .navbar-desktop-logo img {
    max-height: 100%;
  }

  .navbar-desktop ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
  }

  .navbar-desktop ul li a {
    padding: 1rem;
    color: var(--lightTextColor);
  }

  .navbar-desktop ul li a.active {
    color: #283618;
  }

}