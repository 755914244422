.App {
  min-height: 100vh;
  background-color: var(--backgroundColor);
  position: relative;
  overflow: hidden;
}

.scroll-to-top {
  width: 2.5rem;
  height: 2.5rem;
  position: fixed;
  right: 5vw;
  bottom: 5vh;
  border: none;
  cursor: pointer;
  background-color: #BB86FC;
  z-index: 2;
  border-radius: 50%;
}

.scroll-to-top img {
  width: 60%;
}

.sidebar-disable-scroll {
  height: 100vh;
}

@media screen and (min-width: 1024px) {
  .sidebar-disable-scroll {
    height: auto;
  }
}