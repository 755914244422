.project-theater p, li {
  line-height: 1.5;
}

.project-theater ol {
  list-style-position: inside;
}

.project-theater h2 {
  margin-bottom: .5rem;
}

.project-theater p {
  margin-bottom: 1rem;
}

.project-theater img {
  width: 100%;
}

.project-theater > div {
  margin-bottom: 3rem;
}

.project-intro-imgs img {
  align-self: center;
  justify-self: center;
}

.project-info {
  color: var(--darkTextColor);
}

.project-info div h3 {
  text-decoration: underline;
  margin-bottom: .5rem;
}

.project-vision {
  background-color: #35164D;
  color: white;
}

.project-research-empathy, .project-research-persona {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.project-research-empathy p:nth-of-type(2) {
  margin-top: 1.5rem;
}

.project-research-persona {
  margin-top: 3rem;
}


.project-research-summary-container {
  background-color: #DDBEA9;
}

.project-research-summary {
  border: 5px solid black;
  border-radius: 10px;
}

.project-research-summary h3 {
  margin-bottom: 1rem;
}

.project-research-summary h3:nth-of-type(2) {
  margin-top: 2rem;
}

.project-research-summary span {
  color: #F32A15;
}

.project-wireframes {
  background-color: #C9C9BA;
}

.project-wireframes-img-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.project-mockups {
  background-color: #C9C9BA;
}


.project-comparison h3 {
  margin-bottom: .5rem;
  text-transform: uppercase;
}

.project-app-demo p {
  text-transform: uppercase;
}

.project-each-demo img{
  border-radius: 10px;
}


.project-figma-link h2 {
  text-align: center;
  color: #9310DA;
}

.apply-padding {
  padding: 2rem .5rem;
}

@media screen and (min-width: 768px) {
  .project-info-ul {
    display: flex;
    gap: 5rem;
  }

 .project-theater p, li {
    line-height: 1.75;
  }

  .apply-padding {
    padding: 2rem;
  }

  .project-info {
    display: flex;
    justify-content: space-between;
  }

  .project-vision-groups {
    display: flex;
  }

  .project-vision-groups:nth-of-type(2) {
    margin-top: 3rem;
  }

  .project-vision-groups > * {
    flex: 1 1 0;
  }

  .project-research-empathy, .project-research-persona {
    flex-direction: row;
  }

  .project-research-empathy > *, .project-research-persona > * {
    flex: 1 1 0;
  }

  .project-preparation-groups {
    display: flex;
    gap: 5rem;
    /* justify-content: center; */
  }

  .project-preparation h2:nth-of-type(2) {
    margin-top: 2rem;
  }

  .project-preparation-groups p {
    text-align: center;
  }

  .project-wireframes-img-container {
    /* display: flex; */
    flex-direction: row;
    /* gap: 1rem; */
    /* justify-content: space-between; */
  }

  /* .project-wireframes-img-container > * {
    flex: 1 1 0;
  } */

  .project-wireframes-img-container div:nth-of-type(1) {
    flex: 1 1 42%;
  }

  .project-wireframes-img-container div:nth-of-type(2) {
    flex: 1 1 54%;
  }

  .project-usability img{
    display: block;
    max-width: 80%;
    margin: 0 auto;
  }

  .project-mockups-groups {
    display: flex;
    gap: 1rem;
  }

  

  .project-comparison-groups {
    display: flex;
    align-items: center;
  }

  .project-comparison-groups > * {
    flex: 1 1 0;
  }

  .project-app-demo p {
    margin-top: 1rem;
    text-align: center;
  }

  .project-comparison-groups-right {
    text-align: right;
  }

  .project-each-demo img{
    display: block;
    max-width: 40%;
    margin: 0 auto;
  }

 
 
}