.footer-container {
  height: 25vh;
  background-color: #053225;
  margin-top: 10rem;
  color: white;
  position: relative;
  padding: 1rem;
}

.footer {
  text-align: center;
}

.footer a {
  display: block;
}

.footer div:nth-of-type(1) {
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.footer div:nth-of-type(2) a img {
  width: 1.5rem;
}


.rights {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .footer {
    padding: 2rem;
    max-width: 80%;
    margin: 0 auto;
  }
}