@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Inter:wght@100;200;300;400;500&display=swap');

:root {
  --navbarHeight: 12vh;
  --backgroundColor: #FFF1E6;
  --lightTextColor: #ACADAF;
  --darkTextColor: #283618;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  max-width: 90%;
  margin: 0 auto;
}

@media screen and (min-width: 1440px) {
  .container {
    max-width: 60%;
  }
}