.about {
  margin-top: 3rem;

}

.about-profile {
  margin-bottom: 5rem;
}

.about-profile > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.about-profile img {
  max-width: 50%;
}

.about h2 {
  margin-bottom: 1.5rem;
  font-weight: 400;
}

.about p {
  line-height: 1.5;
}

.about-profile p:nth-of-type(2) {
  margin: 1.5rem 0;
}

.about-interests {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  gap: 1rem;
}

.about-interests img {
  width: 100%;
}

.about-passionate h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 1024px) {
  .about-profile {
    display: flex;
  }

  .about-profile > div:nth-of-type(1) {
    flex: 1 1 40%;
    align-items: flex-start;
  }

  .about-profile > div:nth-of-type(2) {
    flex: 1 1 60%;
  }

  .about-profile img {
    width: 100%;
  }
}